@import '~antd/dist/antd.css';

.page {
  font-family: "Libera", Helvetica, Arial, sans-serif;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgb(218, 255, 255);
  background-size: cover;
  background-position: center; 
}

.page .flex-box {
  min-height: 1000px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.sub-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}

.card {
  padding: 5px;
  background-color: rgb(250, 243, 224);
  border-radius: 3px;
  box-shadow: 1px 1px 4px gray;
}

.text {
  padding: 20px;
  flex: 1;
}

.special {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.reverted {
  flex-direction: row-reverse;
}

.reverted .special .ant-image {
  justify-content: flex-end;
  display: flex;
}

.vertical-image {
  box-shadow: 3px 3px 8px gray;
  width: 60%;
} 

.sub-page h1 {
  text-align: center;
}



@media only screen and (max-width: 767px) {
  .section {
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px; 
  }
  .section .special .ant-image {
    display: flex;
    justify-content: center;
  }
  .sub-page h1 {
    font-size: 20px;
  }
  .sub-page h2 {
    font-size: 16px;
    text-align: center;
  }
  .vertical-image {
    box-shadow: 3px 3px 8px gray;
    width: 80%;
  } 
}