.our-priests .vertical-image {
    width: 60%;
  }

  .priests>div {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }

.priests>:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.priests>:nth-child(2) div {
  display: flex;
  justify-content: flex-end;
}