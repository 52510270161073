@import '~antd/dist/antd.css';

.page-content {
  margin: 0;
  min-height: 100vh;
  max-width: 1270px;
  padding: 10px;
  z-index: 2;
  background-color: rgba(255, 249, 218, 0.8);
  border-radius: 6px;
  padding-top: 60px;
}

@media only screen and (max-width: 767px) {

  .page-content {
    max-width: 100vw;
  }
}