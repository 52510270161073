.fixed-menu {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.pic-link {
  position: fixed;
  width: 180px;
  height: auto;
  z-index: 200;
  border-radius: 10px;
}

.page .logo {
  width: 60%;
  height: auto;
  background-color:rgb(109, 86, 22);
  border-radius: 0 0 10px 0;
}

.fixed-menu .ant-btn-primary {
  background-color:rgb(109, 86, 22);
  border: none;
  font-size: 20px;
  font-family: "Georgia", Helvetica, Arial, serif;
  height: 50px;
}

.fixed-menu .ant-menu-horizontal > .ant-menu-item {
  vertical-align: baseline;
}

.menu-list {
  padding-left: 180px;
  background-color:rgb(109, 86, 22);
  display: flex;
  justify-content: space-evenly;
}

@media only screen and (max-width: 767px) {
  .menu-list {
    padding-left: 180px;
    background-color:rgb(109, 86, 22);
    display: flex;
    justify-content: flex-end;
    height: 44px;
    padding-right: 5%;
    align-items: center;
  }
  .menu-list svg {
    color: white;
    width: 40px;
    height: auto;
  }
  .pic-link {
    position: fixed;
    height: auto;
    z-index: 200;
    border-radius: 10px;
  }

  .ant-drawer .ant-drawer-content {
    background-color:rgb(109, 86, 22);
  }
  .ant-drawer .ant-drawer-header {
    background-color:rgb(109, 86, 22);
    border-bottom: 0px solid white;
    color: white;
  }
  .ant-drawer .ant-drawer-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .ant-drawer .ant-drawer-body a {
    display: flex;
    width: 100%;
    padding: 10px 0px;
  }
  .ant-drawer .ant-drawer-body button {
    width: 100%;
  }
  .ant-drawer svg {
    color: white;
  }
  .ant-drawer .ant-drawer-title {
    color: white;
  }

  .ant-drawer-body .ant-btn-primary {
    background-color:rgb(109, 86, 22);
    border: none;
    font-size: 20px;
    font-family: "Georgia", Helvetica, Arial, serif;
    height: 50px;
  }

  .page .logo {
    width: 100%;
  }

}