
.section.most-important {
  width: 100%;
}

.section.title {
  padding: 0 100px;
  flex: 1;
  justify-content: center;
  font-family: "Georgia", Helvetica, Arial, serif;
  margin-top: 20px;
  background-color:rgb(109, 86, 22);
  color: white;
}

.section.title h1 {
  color: white;
  font-weight:600;
}

.section.special-info {
  padding: 0 50px;
  flex: 1;
  justify-content: center;
  font-family: "Georgia", Helvetica, Arial, serif;
  margin-top: 20px;
}

.section.announcement {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: "Georgia", Helvetica, Arial, serif;
  flex-direction: column;
}

.carousel {
  padding: 0 40px;
  width: 100%;
  flex: 1;
}

.ant-carousel {
  flex: 1;
}

.ant-carousel {
  box-shadow: 3px 3px 8px gray;
}

.about .special {
  justify-content: flex-end;
}

.section.map {
  justify-content: flex-start;
  width: 100%;
}

.map .special {
  justify-content: flex-end;
}

.map .vertical-image {
  width: 60%;
}

@media only screen and (max-width: 767px) {

  .section.title {
    padding: 0 40px;
  }

  .section.most-important {
    align-items: center;
  }
  .carousel {
    padding: 0 40px;
    width: 100%;
  }

  .section.map {
    width: auto;
  }
}